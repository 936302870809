import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";

const App = () => {
  const [searchParams] = useSearchParams();

  const sendLog = async (logdata) => {
    const licenseKey = '5086e40d2005ba12935918236b56f471cd4cNRAL';
    await axios.post(`https://log-api.newrelic.com/log/v1?Api-Key=${licenseKey}`, logdata);
  }

  const verify = async (serial) => {
    await axios.get(`https://us-central1-kiosklogstest.cloudfunctions.net/serialNewRelic?serial=${serial}`);
    sendLog({
      message: 'Serial Validated',
      timestamp: Date.now(),
      client: 'Restaurant Default',
      environment: 'uat',
      organisation: 'TYIMXJyrsby3hAf4Z1Wk',
      storeId: 'fm-generic',
      serial,
      level: 'info'
    })
  }

  useEffect(() => {
    window.newrelic.setCustomAttribute('client', 'Restaurant Default');
    window.newrelic.setCustomAttribute('environment', 'uat');
    window.newrelic.setCustomAttribute('organisation', 'TYIMXJyrsby3hAf4Z1Wk');
    window.newrelic.setCustomAttribute('storeId', 'fm-generic');
    const serial = searchParams.get('serial');
    if (serial) {
      window.newrelic.setCustomAttribute('serial', serial);
      const logdata = {
        message: 'Visit App',
        timestamp: Date.now(),
        client: 'Restaurant Default',
        environment: 'uat',
        organisation: 'TYIMXJyrsby3hAf4Z1Wk',
        storeId: 'fm-generic',
        serial,
        level: 'debug'
      }
      window.newrelic.addPageAction('AppPageAction', logdata);
      sendLog(logdata)
      verify(serial)
    }
  }, [searchParams])

  return (
    <>
      <h1>{`Serial: ${searchParams.get('serial')}`}</h1>
      <div>
        <ul>
          <li><Link to={`home`}>Home Page</Link></li>
          <li><Link to={`test`}>Test Serial</Link></li>
        </ul>
      </div>
    </>
  );
}

export default App;
