import { useEffect } from "react";
import axios from "axios";

const Test = () => {

  const sendLog = async (logdata) => {
    const licenseKey = '5086e40d2005ba12935918236b56f471cd4cNRAL';
    await axios.post(`https://log-api.newrelic.com/log/v1?Api-Key=${licenseKey}`, logdata);
  }

  useEffect(() => {
    const logdata = {
      message: 'Visit Test',
      timestamp: Date.now(),
      client: 'Restaurant Default',
      environment: 'uat',
      organisation: 'TYIMXJyrsby3hAf4Z1Wk',
      storeId: 'fm-generic',
      level: 'debug'
    }
    window.newrelic.addPageAction('TestPageAction', logdata);
    sendLog(logdata)
  }, [])

  return (
    <div>Test</div>
  );
}

export default Test;
