import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import axios from "axios";

export default function ErrorPage() {
  const error = useRouteError();

  const sendLog = async (logdata) => {
    const licenseKey = '5086e40d2005ba12935918236b56f471cd4cNRAL';
    await axios.post(`https://log-api.newrelic.com/log/v1?Api-Key=${licenseKey}`, logdata);
  }

  useEffect(() => {
    const logdata = {
      message: 'Error Page',
      timestamp: Date.now(),
      client: 'Restaurant Default',
      environment: 'uat',
      organisation: 'TYIMXJyrsby3hAf4Z1Wk',
      storeId: 'fm-generic',
      level: 'error'
    }
    window.newrelic.addPageAction('ErrorPageAction', logdata);
    newrelic.noticeError(error, logdata)
    sendLog(logdata)
  }, [])

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}